import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../actions/authSlice'
import { useRefreshMutation } from '../../api/authApiSlice'
import { Skeleton } from '@mui/material'
import {  Outlet } from 'react-router-dom'
// import { Main } from '../main/Main'
 
 const PersistLogin = () => {

    const persist = true
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    // const location = useLocation()
console.log(token)
    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError
    }] = useRefreshMutation()

    useEffect(() => {

        console.log("Useffect run : ")
        console.log(effectRan.current === true)
        if (effectRan.current === true) return
        console.log("Useffect continue")
        effectRan.current = true

        const verifyRefreshToken = async () => {
            try {
                await refresh()
                console.log("Useffect refreshed")
                setTrueSuccess(true)
            }
            catch (err) {
                console.log(err)
            }
        }

        if (!token && persist) verifyRefreshToken()
      
        // eslint-disable-next-line
    }, [])


    
    let content = <Skeleton />
    if (!persist) { // persist: no
        console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        console.log('loading')
        content = <Skeleton color={"#FFF"} />
    } else if (isError) { //persist: yes, token: no
        console.log('error')
        content = <Outlet />
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        console.log('token and uninit')
        content = <Outlet />
    }

    return content

 }
 
 export default PersistLogin